/* Base styles */
.blog-page {
  font-family: Arial, sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.page-title {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.post-list {
  list-style: none;
  padding: 0;
  width: 100%;
  margin-top: 75px;
  margin-bottom: 75px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.post-item {
  width: calc(33.33% - 20px);
  margin-bottom: 40px;
  position: relative;
}

.post-thumbnail-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.post-thumbnail {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
}

.post-title {
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  color: #fff;
}

/* Desktop styles */
@media (min-width: 769px) {
  .overlay {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .overlay:hover {
    opacity: 1;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .post-item {
    width: calc(50% - 10px);
  }

  .overlay {
    opacity: 1;
    background-color: transparent; /* Remove the dark background on mobile */
  }

  .post-title {
    font-family: inherit;
    font-size: 1.5rem;
    text-shadow: 1px 1px 3px rgba(0,0,0,0.8); /* Add text shadow for better readability */
  }
}

@media (max-width: 480px) {
  .post-item {
    width: 100%;
  }
}